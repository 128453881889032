import React from "react";
import PageNotFoundError from "./PageNotFound";
import SiteExpiredError from "./SiteExpired";
import SiteNotFoundError from "./SiteNotFound";
import UnknownError from "./UnknownError";
import CustomErrorPage from "./CustomErrorPage";

export function Error({
  errorCode,
  errorPages,
  siteData,
  pagesMeta,
}: {
  errorCode: number;
  errorPages?: string;
  siteData?: string;
  pagesMeta?: string;
}) {
  if (errorCode === 9004) {
    return <SiteNotFoundError />;
  }

  if (errorCode === 9009) {
    return <SiteExpiredError />;
  }

  if (errorCode === 9005) {
    return (
      <CustomErrorPage
        pagesMeta={pagesMeta}
        siteData={siteData}
        errorPages={errorPages}
      />
    );
  }

  if (errorCode === 404) {
    return <PageNotFoundError />;
  }

  return <UnknownError />;
}
