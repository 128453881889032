import { SiteClosedIcon } from "assets/Icons";
import React from "react";
import classes from "./Error.module.scss";

function SiteExpiredError() {
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        <SiteClosedIcon />
        <h2 className={classes.heading}>We will be back soon</h2>
        <p className={classes.text}>We’re temporarily closed!</p>
      </div>
    </div>
  );
}

export default SiteExpiredError;
