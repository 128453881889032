import React from "react";
import injectSectionElements from "@wuilt/section-elements/build/injectSectionElements";
import { getSectionDesign } from "@wuilt/sections-designs";
import createSectionProps from "../helpers/createSectionProps";
import { Locale, UniversalData, UniversalSections } from "types";

interface GlobalNavbarProps {
  locale: Locale;
  universalData: UniversalData;
  universalSections: UniversalSections;
}

const DEFAULT_LOGO_URL =
  "https://assets.wuiltsite.com/defaults/default-logo.svg";
const DEFAULT_LOGO_SIZE = "medium";

const GlobalNavbar = ({
  locale,
  universalData: universal,
  universalSections,
}: GlobalNavbarProps) => {
  const [isScrolling, setIsScrolling] = React.useState(false);

  const updateIsScrolling = () => {
    setIsScrolling(window.pageYOffset > 0 ? true : false);
  };

  React.useEffect(() => {
    const watchScroll = () => {
      window.addEventListener("scroll", updateIsScrolling);
    };
    watchScroll();
    return () => {
      window.removeEventListener("scroll", updateIsScrolling);
    };
  });
  const section = universalSections?.header_expanded[locale];
  const [category, designId] = section.section_design.split("#");
  const values = section?.section_value;
  const Section = getSectionDesign(category, designId);
  if (!Section) {
    return <div>Section {section.section_design} Not Found</div>;
  }
  const { component, meta, styles } = Section;
  const SectionComponent = injectSectionElements(component);
  const navbarProps = createSectionProps(values, universal, meta) as any;

  const globalNavbarSettings = section?.section_settings;

  const { style, sticky, colors } = globalNavbarSettings;

  const isGlobalNavbarTransparent =
    !!globalNavbarSettings?.style?.isTransparent;

  const isNavbarSticky = !!globalNavbarSettings?.style?.isSticky;

  let logoUrl: string;

  let logoSize: string;

  if (
    isScrolling &&
    style?.isSticky &&
    sticky?.isAlternateLogo &&
    sticky?.stickyLogoUrl !== "empty"
  ) {
    logoUrl = sticky?.stickyLogoUrl;
    logoSize = sticky?.stickyLogoSize;
  } else {
    logoUrl = navbarProps?.logo?.url ?? navbarProps?.logo ?? DEFAULT_LOGO_URL;
    logoSize = navbarProps?.logo?.size ?? DEFAULT_LOGO_SIZE;
  }

  const logoValue = { size: logoSize, url: logoUrl };

  let navbarStyles: any;

  const globalNavbarSettingsProps = {
    globalNavbarSettings: {
      backgroundColor: "",
      navLinksColor: "",
    },
  };

  if (isNavbarSticky && isScrolling) {
    if (isGlobalNavbarTransparent) {
      navbarStyles = {
        width: "100%",
        zIndex: "900",
        position: "fixed",
        top: 0,
      };
    } else {
      navbarStyles = {
        width: "100%",
        zIndex: "900",
        position: "sticky",
        top: 0,
      };
    }

    globalNavbarSettingsProps.globalNavbarSettings = {
      backgroundColor: sticky?.backgroundColor,
      navLinksColor: sticky?.navLinksColor,
    };
  } else {
    if (isGlobalNavbarTransparent) {
      navbarStyles = {
        width: "100%",
        zIndex: "900",
        position: "absolute",
      };
    }
    globalNavbarSettingsProps.globalNavbarSettings = {
      backgroundColor: isGlobalNavbarTransparent
        ? "transparent"
        : colors?.backgroundColor,
      navLinksColor: colors?.navLinksColor,
    };
  }

  return (
    <div style={{ overflow: "", ...navbarStyles }}>
      <SectionComponent
        classes={styles}
        {...navbarProps}
        {...globalNavbarSettingsProps}
        logo={{ ...navbarProps?.logo, ...logoValue }}
      />
    </div>
  );
};

export default GlobalNavbar;
