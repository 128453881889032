export const headerToHeroMap = {
  "header_home#0011": "hero#0001",
  "header_home#0012": "hero#0002",
  "header_home#0013": "hero#0003",
  "header_home#0014": "hero#0004",
  "header_home#0015": "hero#0005",
  "header_home#0016": "hero#0006",
  "header_home#0017": "hero#0007",
  "header_home#0018": "hero#0008",
  "header_home#0019": "hero#0009",
  "header_home#0020": "hero#0010",
  "header_home#0021": "hero#0011",
  "header_home#0022": "hero#0012",
  "header_home#0023": "hero#0013",
  "header_home#0024": "hero#0014",
  "header_home#0025": "hero#0015",
  "header_home#0026": "hero#0016",
  "header_home#0027": "hero#0017",
  "header_home#0028": "hero#0018",
  "header_home#0029": "hero#0019",
  "header_home#0030": "hero#0020",
  "header_home#0031": "hero#0021",
  "header_home#0032": "hero#0022",
  "header_home#0033": "hero#0023",
  "azzafahmy#header-home-01": "azzafahmy#hero-01",
  "damas#home-header-01": "damas#hero-01",
  // 'damas#home-header-02' : 'hero#0001',
  // 'damas#home-header-03' : 'hero#0001',
  // 'damas#home-header-04' : 'hero#0001',
};

export const sectionsNavSettingsFallback = {
  "header_home#0011": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#FFFFFF", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0012": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#FFFFFF", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0013": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0014": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0015": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0016": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#FFFFFF", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0017": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#FFFFFF", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0018": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0019": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#667085", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0020": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0021": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0022": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#667085", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0023": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#667085", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0024": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFيFF" },
    },
  },
  "header_home#0025": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#667085", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0026": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0027": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0028": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0029": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0030": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0031": {
    private: 0,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#FFFFFF", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0032": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "header_home#0033": {
    private: 0,
    section_settings: {
      style: { isTransparent: false, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "azzafahmy#header-home-01": {
    private: 1,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
  "damas#home-header-01": {
    private: 1,
    section_settings: {
      style: { isTransparent: true, isSticky: false },
      sticky: {
        isAlternateLogo: false,
        stickyLogoUrl: "empty",
        stickyLogoSize: "small",
        navLinksColor: "#1D2939",
        backgroundColor: "#FFFFFF",
      },
      colors: { navLinksColor: "#000000", backgroundColor: "#FFFFFF" },
    },
  },
};

const sections = {
  11: {
    sectionId: "header_home#0011",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  12: {
    sectionId: "header_home#0012",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  13: {
    sectionId: "header_home#0013",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  14: {
    sectionId: "header_home#0014",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  15: {
    sectionId: "header_home#0015",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  16: {
    sectionId: "header_home#0016",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  17: {
    sectionId: "header_home#0017",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  18: {
    sectionId: "header_home#0018",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  19: {
    sectionId: "header_home#0019",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  20: {
    sectionId: "header_home#0020",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  21: {
    sectionId: "header_home#0021",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  22: {
    sectionId: "header_home#0022",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  23: {
    sectionId: "header_home#0023",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  24: {
    sectionId: "header_home#0024",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  25: {
    sectionId: "header_home#0025",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  26: {
    sectionId: "header_home#0026",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  27: {
    sectionId: "header_home#0027",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  28: {
    sectionId: "header_home#0028",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  29: {
    sectionId: "header_home#0029",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  30: {
    sectionId: "header_home#0030",
    isTransparent: true,
    navLinksColor: "#000000",
  },
  31: {
    sectionId: "header_home#0031",
    isTransparent: true,
    navLinksColor: "#FFFFFF",
  },
  32: {
    sectionId: "header_home#0032",
    isTransparent: false,
    navLinksColor: "#000000",
  },
  33: {
    sectionId: "header_home#0033",
    isTransparent: false,
    navLinksColor: "#000000",
  },
};

export default sections;
