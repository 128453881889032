import { SectionValueTypes } from "@wuilt/sections-designs";
import {
  FooterSectionValue,
  GlobalNavbarSectionValue,
  Page,
  CommonType,
  UniversalData,
} from "types";

function objectMap(object: Record<string, any>, mapper: any) {
  return Object.keys(object).reduce((acc, currentKey) => {
    return {
      ...acc,
      [currentKey]: mapper(object[currentKey], currentKey),
    };
  }, {});
}

const repeatedValue = (array: CommonType[]) =>
  array.map((current) => objectMap(current, (value) => value.value));

const repeatedGroup = (groups, valuesMeta) =>
  groups.map((group) => objectMap(group, (fieldValue) => fieldValue));

const value = (valueObject: CommonType, valueMeta, defaultValue) => {
  if (valueMeta.type === SectionValueTypes.GROUP && valueMeta.withoutIds) {
    return repeatedGroup(valueObject, valueMeta.values);
  }

  if (valueObject && valueObject.value) {
    return valueObject.value;
  }

  if (valueObject && Array.isArray(valueObject)) {
    return repeatedValue(valueObject);
  }

  return defaultValue;
};

const getDefaultValue = (
  meta: Record<string, any>,
  prop: string,
  locale: string
) => {
  if (!meta) return false;
  const defaultValue =
    meta[prop].defaultValue[locale] || meta[prop].defaultValue;
  return defaultValue;
};

const createSectionProps = (
  values:
    | FooterSectionValue
    | GlobalNavbarSectionValue
    | Page["page_sections"][0]["section_value"],
  universal: UniversalData,
  meta: Record<string, any>,
  locale = "default"
) => {
  const props = meta
    ? Object.keys(meta).reduce((acc, currentKey) => {
        let cValue = "";
        if (meta[currentKey].source === "UNIVERSAL") {
          cValue = universal[currentKey] || "not";
        } else {
          cValue = values
            ? value(
                values[currentKey],
                meta[currentKey],
                getDefaultValue(meta, currentKey, locale)
              )
            : getDefaultValue(meta, currentKey, locale);
        }
        return {
          ...acc,
          [currentKey]: cValue,
        };
      }, {})
    : {};

  return props;
};

export default createSectionProps;
