export const C404 = () => {
  return (
    <svg width="483" height="180" viewBox="0 0 483 180">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#5F738C"
              fillRule="nonzero"
              d="M116.16 176.4L116.16 140.16 137.76 140.16 137.76 106.8 116.16 106.8 116.16 71.04 75.84 71.04 75.84 106.8 44.4 106.8 89.28 8.16 47.52 8.16-.24 110.4-.24 140.16 75.84 140.16 75.84 176.4zM452.16 176.4L452.16 140.16 473.76 140.16 473.76 106.8 452.16 106.8 452.16 71.04 411.84 71.04 411.84 106.8 380.4 106.8 425.28 8.16 383.52 8.16 335.76 110.4 335.76 140.16 411.84 140.16 411.84 176.4z"
              transform="translate(-478 -291) translate(479 291)"
            />
            <path
              fill="#1A1A1A"
              d="M281.575 82.398c-8.416 0-15.244-6.802-15.244-15.208 0-8.396 6.828-15.208 15.244-15.208s15.244 6.812 15.244 15.208c0 8.406-6.828 15.208-15.244 15.208M195.19 67.19c0-8.396 6.829-15.208 15.245-15.208s15.244 6.812 15.244 15.208c0 8.406-6.828 15.208-15.244 15.208S195.19 75.596 195.19 67.19M246 0c-76.222 0-76.2 70.97-76.2 70.97v108.272l19.064-25.319 19.03 25.32 19.034-25.32 19.034 25.32 19.034-25.32 19.035 25.32 19.05-25.32 19.119 25.32V70.968S322.22 0 246 0"
              transform="translate(-478 -291) translate(479 291)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
