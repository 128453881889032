import { C404 } from "assets/Icons";
import Link from "next/link";
import React from "react";
import classes from "./Error.module.scss";

export default function PageNotFoundError() {
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        <h2 className={classes.heading}>Page not found!</h2>
        <div>
          <C404 />
        </div>
        <p className={classes.text}>
          Sorry we’re unable to find the page you’re looking for.
        </p>
        <Link href="/" title="Homepage" className={classes.button}>
          Homepage
        </Link>
      </div>
    </div>
  );
}
