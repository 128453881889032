import React from "react";
import injectSectionElements from "@wuilt/section-elements/build/injectSectionElements";
import { getSectionDesign } from "@wuilt/sections-designs";
import createSectionProps from "../helpers/createSectionProps";
import {
  Animation,
  FooterSectionValue,
  Page,
  UniversalData,
  SectionSettings,
  GlobalNavbarSectionValue,
} from "types";
import { convertHexToRgba } from "@/helpers/convertHexToRgba";
import classes from "./Styles/Section.module.scss";
import tinycolor from "tinycolor2";

function getContrastColor(color) {
  const colorObj = tinycolor(color);

  if (colorObj.isValid()) {
    return colorObj.isLight() ? "black" : "white";
  }

  return "black";
}

interface SectionProps {
  id: string;
  design: string;
  values:
    | Page["page_sections"][0]["section_value"]
    | FooterSectionValue
    | GlobalNavbarSectionValue;
  universal: UniversalData & { pageName?: string };
  animation: Animation;
  sectionSettings?: SectionSettings;
  addExtraPaddingForGlobalNavbar?: boolean;
}

const initialSectionSettings = {
  background: { image: "", overlay: "", color: "" },
};
const Section = ({
  id: sectionId,
  design,
  values,
  universal,
  animation,
  addExtraPaddingForGlobalNavbar,
  sectionSettings = initialSectionSettings,
}: SectionProps) => {
  const [category, designId] = design.split("#");
  const Section = getSectionDesign(category, designId);
  if (!Section) {
    return <div>Section Not Found {design}</div>;
  }
  const { component, meta, styles } = Section;
  const SectionComponent = injectSectionElements(component);
  const sectionProps = createSectionProps(values, universal, meta, undefined);

  const {
    background: { image, overlay, color },
  } = sectionSettings;

  const backgroundStyles: React.CSSProperties = {
    backgroundImage:
      !!image &&
      `linear-gradient(${convertHexToRgba(overlay)},${convertHexToRgba(
        overlay
      )}),url(${image})`,
    backgroundColor: `${color}`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  const textColor = getContrastColor(color || convertHexToRgba(overlay));
  const isCustomSection = category === "custom_section";
  const embedDesign4 = design === "embed#0004";
  const base = {
    "--text-color": textColor,
  } as React.CSSProperties;
  const style = isCustomSection ? base : { ...base, ...backgroundStyles };
  return (
    <div
      id={sectionId}
      data-aos-offset="100"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos={animation?.name}
      style={style}
    >
      <div
        className={
          addExtraPaddingForGlobalNavbar && !embedDesign4
            ? classes.extraPaddingForGlobalNavbar
            : ""
        }
      >
        <SectionComponent
          classes={styles}
          {...sectionProps}
          sectionId={sectionId}
        />
      </div>
    </div>
  );
};
export default Section;
