import { Locale } from "types";

export const sortByKey = (key: "sort_order") => (a, b) =>
  a[key] === b[key] ? 0 : a[key] > b[key] ? 1 : -1;

const pagesSort = sortByKey("sort_order");

function checkIsEmptyId(id: any) {
  return !id || id === null || id === 0 || id === "0";
}
function createNestedTree(items, parent = 0, level = 0) {
  return items
    .filter((item) => {
      if (parent === 0) {
        return checkIsEmptyId(item.parent_id);
      }
      return item.parent_id === parent;
    })
    .reduce(
      (acc, current) =>
        [
          ...acc,
          {
            level,
            ...{
              ...current,
            },
            ...(current.id &&
            items.filter((restPage) => restPage.parent_id == current.id)
              .length > 0
              ? {
                  hasChildren: true,
                  children: createNestedTree(items, current.id, level + 1),
                }
              : {}),
          },
        ].sort(pagesSort),
      []
    );
}

export const createNestedTreeForLocale = (items: any[], locale: Locale) => {
  const itemsForLocale = items?.filter((i) =>
    locale ? i.language === locale : true
  );
  return createNestedTree(itemsForLocale);
};
