export const convertHexToRgba = (color) => {
  const removeHash = color?.replace("#", "");
  const hex = removeHash?.length === 3 ? removeHash + removeHash : removeHash;
  const red = parseInt(hex?.substring(0, 2), 16);
  const green = parseInt(hex?.substring(2, 4), 16);
  const blue = parseInt(hex?.substring(4, 6), 16);
  const opacity = parseInt(hex?.substring(6, 8), 16) || 255;
  return `rgba(${red}, ${green}, ${blue}, ${
    Math.round((opacity / 255) * 100) / 100
  })`;
};
