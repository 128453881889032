import React from "react";
import { WuiltLogo } from "assets/Icons";
import classes from "./Error.module.scss";

function SiteNotFoundError() {
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        <WuiltLogo size={120} />
        <h2>
          This domain is reserved to a wuilt site that has not been published
          yet
        </h2>
      </div>
    </div>
  );
}

export default SiteNotFoundError;
