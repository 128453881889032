/* eslint-disable @next/next/no-img-element */
import { ImageLoaderProps } from "next/image";

export function imageLoader(
  props: ImageLoaderProps & { height: string | number }
) {
  const { src } = props;
  if (typeof src !== "string") {
    return props.src;
  }
  return `${props.src}`;
}

export default function Image(props) {
  const imageSrc = imageLoader(props);

  return <img {...props} src={imageSrc} alt="" />;
}
