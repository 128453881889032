export const C500 = () => {
  return (
    <svg width="546" height="182" viewBox="0 0 546 182">
      <defs>
        <path id="p2pw08vmva" d="M0 179.922L197.418 179.922 197.418 0 0 0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#5F738C"
              fillRule="nonzero"
              d="M75.19 175.77c14.133 0 26.541-2.465 37.224-7.396 10.683-4.93 18.9-11.915 24.652-20.954 5.752-9.04 8.628-19.557 8.628-31.555 0-10.19-2.752-19.228-8.258-27.117-5.506-7.889-13.312-14.052-23.42-18.49-10.107-4.437-21.817-6.655-35.129-6.655h-15.53V37.225h74.695V.986H21.94v98.609c9.697-.658 25.474-.986 47.333-.986 9.367 0 16.393 1.766 21.077 5.3 4.684 3.533 7.026 8.67 7.026 15.408 0 6.738-2.383 12.038-7.149 15.9s-11.422 5.793-19.968 5.793c-15.12 0-30.651-6.41-46.593-19.228l-16.763 32.54c9.532 6.41 20.42 11.587 32.664 15.531 12.244 3.945 24.118 5.917 35.622 5.917zM458.037 174.044c24.488 0 43.347-7.6 56.577-22.803 13.23-15.202 19.845-36.937 19.845-65.205s-6.615-49.962-19.845-65.082c-13.23-15.12-32.089-22.68-56.577-22.68-24.816 0-43.84 7.56-57.07 22.68-13.23 15.12-19.844 36.814-19.844 65.082 0 28.268 6.615 50.003 19.845 65.205 13.23 15.202 32.253 22.803 57.07 22.803zm0-33.527c-10.847 0-18.653-4.232-23.42-12.695-4.765-8.464-7.148-22.393-7.148-41.786 0-19.229 2.383-33.075 7.149-41.539 4.766-8.464 12.572-12.696 23.42-12.696 10.518 0 18.2 4.232 23.05 12.696 4.847 8.464 7.272 22.31 7.272 41.54 0 19.228-2.425 33.115-7.273 41.661s-12.531 12.82-23.05 12.82z"
              transform="translate(-453 -379) translate(453 381)"
            />
            <g transform="translate(-453 -379) translate(453 381) translate(162.704)">
              <path
                fill="#1A1A1A"
                d="M124.23 139.045c1.266-1.5 1.899-3.6 1.899-6.3v-10.667h-16.813v10.667c0 2.7.684 4.8 2.053 6.3 1.369 1.498 3.538 2.248 6.508 2.248s5.088-.75 6.354-2.248zm-20.57 9.033c-2.79-3.316-4.184-8.051-4.184-14.206v-11.794H61.63v-11.76h74.416v24.323c0 5.606-1.472 10.077-4.416 13.41-2.945 3.335-7.503 5.001-13.675 5.001-6.741 0-11.506-1.659-14.295-4.974z"
              />
              <mask id="4l8gu26g8b" fill="#fff">
                <use xlinkHref="#p2pw08vmva" />
              </mask>
              <path
                fill="#5F738C"
                d="M54.929 26.975h123.998v-8.902H54.93v8.902zm-36.71 134.874h160.708V45.048H18.22v116.801zm4.477-143.988c2.488 0 4.504 2 4.504 4.469 0 2.467-2.016 4.468-4.504 4.468-2.487 0-4.504-2-4.504-4.468s2.017-4.469 4.504-4.469zm18.491 0c2.488 0 4.504 2 4.504 4.469 0 2.467-2.016 4.468-4.504 4.468-2.487 0-4.504-2-4.504-4.468s2.017-4.469 4.504-4.469zM9.181 0C4.14 0 0 3.919 0 8.92v162.033c0 5 4.14 8.97 9.18 8.97h178.998c5.044 0 9.24-3.97 9.24-8.97V8.92c0-5.001-4.196-8.92-9.24-8.92H9.18z"
                mask="url(#4l8gu26g8b)"
              />
              <path
                fill="#1A1A1A"
                d="M77.176 86.024L65.856 74.791 77.173 63.562 69.625 56.084 58.306 67.311 46.989 56.084 39.443 63.562 50.763 74.796 39.443 86.024 46.989 93.509 58.306 82.281 69.629 93.509zM158.528 86.024L147.208 74.791 158.526 63.562 150.977 56.084 139.658 67.311 128.342 56.084 120.796 63.562 132.115 74.796 120.796 86.024 128.342 93.509 139.658 82.281 150.981 93.509z"
                mask="url(#4l8gu26g8b)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
